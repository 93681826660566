import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { adopt } from "react-adopt";
import TagManager from "react-gtm-module";
import { I18n } from "@deity/falcon-i18n";
import {
  themed,
  Box,
  Text,
  H1,
  NumberInput,
  Button,
  Icon,
  FlexLayout
} from "@deity/falcon-ui";
import { Locale } from "@deity/falcon-ecommerce-uikit/dist/Locale";
import { Breadcrumbs } from "@deity/falcon-ecommerce-uikit/dist/Breadcrumbs";
import { ProductGallery } from "@deity/falcon-ecommerce-uikit/dist/Product/ProductGallery";
import { ProductConfigurableOptions } from "@deity/falcon-ecommerce-uikit/dist/Product/ConfigurableOptions";
import { AddToCartMutation } from "@deity/falcon-ecommerce-uikit/dist/Cart";

import { ProductConfigurator } from "@deity/falcon-ecommerce-uikit/dist/Product/ProductConfigurator";
import { Price } from "@deity/falcon-ecommerce-uikit/dist/Locale";
import { toGridTemplate } from "@deity/falcon-ecommerce-uikit/dist/helpers";
import {
  Product as ProductPage,
  ProductSwitchImg,
  ProductOptions,
  ProductOpinion
} from "benlux-ecommerce-ui";
import * as styles from "./Product.css";
import { OpenSidebarMutation } from "./Sidebar/SidebarMutations";
import { BackInStockForm } from "../../../components/BackInStock";

export const ProductLayout = themed({
  tag: "div",
  defaultTheme: {
    productLayout: {
      display: "grid",
      gridGap: "sm"
    }
  }
});

const Area = {
  gallery: "gallery",
  sku: "sku",
  title: "title",
  description: "description",
  cta: "cta",
  price: "price",
  meta: "meta",
  empty: "empty",
  options: "options",
  error: "error"
};

export const ProductDetailsLayout = themed({
  tag: "article",
  defaultTheme: {
    productDetailsLayout: {
      display: "grid",
      gridGap: "sm",

      gridTemplate: {
        // prettier-ignore
        xs: toGridTemplate([
          ['1fr'],
          [Area.title],
          [Area.sku],
          [Area.gallery],
          [Area.price],
          [Area.error],
          [Area.options],
          [Area.cta],
          [Area.description],
          [Area.meta]
        ]),
        // prettier-ignore
        md: toGridTemplate([
          ['1.5fr', '1fr'],
          [Area.gallery, Area.sku],
          [Area.gallery, Area.title],
          [Area.gallery, Area.price],
          [Area.gallery, Area.options],
          [Area.gallery, Area.cta],
          [Area.gallery, Area.error],
          [Area.gallery, Area.description, '1fr'],
          [Area.gallery, Area.meta]
        ])
      }
    }
  }
});

const ProductDescriptionLayout = themed({
  tag: "div",

  defaultTheme: {
    productDescriptionLayout: {
      css: {
        p: {
          margin: 0
        }
      }
    }
  }
});

/**
 * Combine render props functions into one with react-adopt
 */
const ProductForm = adopt({
  // mutation provides openSidebar() method that allows us to show mini cart once product is added
  openSidebarMutation: ({ render }) => (
    <OpenSidebarMutation>
      {openSidebar => render({ openSidebar })}
    </OpenSidebarMutation>
  ),
  // mutation provides addToCart method which should be called with proper data
  addToCartMutation: ({ render, openSidebarMutation }) => (
    <AddToCartMutation
      onCompleted={(data, ...other) => {
        openSidebarMutation.openSidebar({
          variables: {
            contentType: "cart",
            contextKey: "addSuccess",
            contextItemId: data.addToCart.itemId
          }
        });

        try {
          if (typeof window !== "undefined" && window.product) {
            const { price } = data.addToCart;
            const { product } = window;

            const eventData = {
              id: product.id,
              brand: product.brand.name,
              quantity: window.addToCartQty,
              price: Math.round(price * 1.2 * 100) / 100, // Add VAT
              name: product.brand.name + " - " + product.name,
              variant: ""
            };

            if (product.configurableOptions.length > 0) {
              const { configurableOptions } = product;
              const attributeId = configurableOptions[0].attributeId;
              const selectedValueIndex = document.querySelector(
                '[name="' + attributeId + '"]:checked'
              ).value;
              const selectedOption = configurableOptions[0].values.find(
                option => option.valueIndex === selectedValueIndex
              );
              eventData.variant = selectedOption.label;
            }

            TagManager.dataLayer({
              dataLayer: {
                event: "AddToCart",
                item: eventData
              }
            });
          }
        } catch (err) {
          console.error("Error tracking add to cart event", err);
        }
      }}
      onError={({ graphQLErrors }) => {
        let errorCode = graphQLErrors[0].extensions.code;
        if (
          graphQLErrors[0].message ===
          "Product that you are trying to add is not available."
        ) {
          errorCode = "OUT_OF_STOCK";
        }

        openSidebarMutation.openSidebar({
          variables: {
            contentType: "cart",
            error: errorCode,
            contextKey: "addError"
          }
        });
      }}
    >
      {(addToCart, result) => render({ addToCart, result })}
    </AddToCartMutation>
  ),
  // formik handles form operations and triggers submit when onSubmit event is fired on the form
  formik: ({ sku, validate, addToCartMutation, render }) => (
    <Formik
      initialValues={{ qty: 1 }}
      validate={validate}
      onSubmit={values => {
        if (typeof window !== "undefined") {
          window.addToCartQty = values.qty;
        }
        addToCartMutation.addToCart({
          variables: {
            input: {
              sku,
              ...values,
              qty: parseInt(values.qty, 10)
            }
          }
        });
      }}
    >
      {(...props) => <Form>{render(...props)}</Form>}
    </Formik>
  ),

  // product configurator takes care about interactions between configurable product options and serializes
  // selected data into proper format so GraphQL can use it
  productConfigurator: ({ formik, render }) => (
    <ProductConfigurator
      onChange={(name, value) =>
        formik.setFieldValue(name, value, !!formik.submitCount)
      }
    >
      {render}
    </ProductConfigurator>
  )
});

export class Product extends React.PureComponent {
  createValidator(product, t) {
    return values => {
      const errors = {};

      // handle qty
      if (parseInt(values.qty, 10) < 1) {
        errors.qty = t("product.error.quantity");
      }

      // handle configuration options
      if (product.configurableOptions && product.configurableOptions.length) {
        if (
          !values.configurableOptions ||
          values.configurableOptions.length !==
            product.configurableOptions.length
        ) {
          errors.configurableOptions = t("product.error.configurableOptions");
        }
      }

      // todo: handle bundled products

      return errors;
    };
  }

  render() {
    const { product, trustpilotData } = this.props;
    if (typeof window !== "undefined") {
      window.product = product;
    }
    return (
      <ProductLayout>
        <I18n>
          {t => (
            <ProductForm
              sku={product.sku}
              validate={this.createValidator(product, t)}
            >
              {({
                addToCartMutation: {
                  result: { loading, error, ...result }
                },
                formik: { values, errors, setFieldValue, submitCount },
                productConfigurator
              }) => (
                <>
                  <ProductPage
                    product={product}
                    inputBackInStock={<BackInStockForm product={product} />}
                    disabledButton={loading}
                    onChangeQty={qty => {
                      setFieldValue("qty", qty, !!submitCount);
                    }}
                    error={errors.configurableOptions}
                    defaultValue={String(values.qty)}
                    trustpilotData={trustpilotData}
                    onChangeVariante={ev => {
                      productConfigurator.handleProductConfigurationChange(
                        "configurableOption",
                        ev
                      );
                    }}
                  />
                </>
              )}
            </ProductForm>
          )}
        </I18n>
      </ProductLayout>
    );
  }
}
